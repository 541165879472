// Touch detection
var isTouch = false;

(function() {
    var touchFailsafe = false;

    // Modernizr.touch does not correctly detect Windows Phone touch
    // https://github.com/Modernizr/Modernizr/issues/448
    // if (navigator.userAgent.indexOf('IEMobile') > 0) { // UA detection...
    if (Modernizr.pointerevents) {
        if (navigator.maxTouchPoints !== undefined) {
            if (navigator.maxTouchPoints > 0) {
                touchFailsafe = true;
            }
        }
        else if (navigator.msMaxTouchPoints !== undefined) { // msMaxTouchPoints is IE 10 <=
            if (navigator.msMaxTouchPoints > 0) {
                touchFailsafe = true;
            }
        }
    }

    if (Modernizr.touchevents || touchFailsafe) {
        /* Target touch devices - phones and tablets but not desktop touch monitors  */
        if ((window.matchMedia("(max-device-height: 900px)").matches || window.matchMedia("(max-device-width: 1024px)").matches) || ((window.screen.availHeight < 900) || (window.screen.availWidth < 1024))) {
            isTouch = true;
            document.documentElement.className += " touch";
        }
    } else {
        document.documentElement.className += " no-touch";
    }
})();


// Viewport detection
function detectView() {
    var oldView = false,
        mobileMediaQuery = APP.matchMediaSupport ? window.matchMedia("(max-width: "+ APP.mobileBreakpoint +"px)") : undefined;

    function detect() {
        var mobileMatches = APP.matchMediaSupport ? mobileMediaQuery.matches : win.width() < APP.mobileBreakpoint;

        oldView = APP.View;

        if (!mobileMatches) {
            APP.mobileView = false;
            APP.desktopView = true;
            APP.View = "desktop";
        }
        else {
            APP.mobileView = true;
            APP.desktopView = false;
            APP.View = "mobile";
        }

        if ((oldView !== false) && (APP.View !== oldView)) {
            // view changed
            APP.Elements.footer.data('height', APP.Elements.footer.outerHeight());
        }
        else {
            oldView = APP.View;
        }
    }

    detect();

    if (APP.matchMediaSupport) {
        mobileMediaQuery.addListener(detect);
    }
    else {
        win.on('resize', detect);
    }
}


// Browser detection
(function() {
    var browser = bowser.name.replace(/\s+/g, '').toLowerCase() + '-browser';

    document.documentElement.className += " " + browser;
})();
